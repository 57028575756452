<template>

     <template v-if="currentSchoolyear && adminSchoolyear">

        <el-card shadow="hover">
            <el-row align="middle">
                <el-col :span="12">
                    <div class="text-left">
                        <h3>{{ $t('parentsList') }}</h3>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="text-right">
                        <el-button size="default" @click="addItem()">
                            {{ $t('add') }}
                        </el-button>
                    </div>
                </el-col>
            </el-row>

            <el-divider></el-divider>

            <el-table :data="pagedTableData" :empty-text="$t('noData')" border stripe v-loading="isLoading" style="width: 100%">

                <el-table-column type="index" :index="indexMethod" width="50" fixed> </el-table-column>
                <el-table-column sortable :label="$t('name')" prop="name" width="200" fixed>
                    <template #default="scope">
                        {{ getTitleLabel(scope.row.title) + ' ' + scope.row.name }}
                    </template>
                </el-table-column>
                <el-table-column sortable :label="$t('phone')" prop="phone" width="150"> </el-table-column>
                <el-table-column sortable :label="$t('email')" prop="email" width="170"> </el-table-column>
                <el-table-column sortable :label="$t('nationality')" prop="nationality" width="150"> </el-table-column>
                <el-table-column sortable :label="$t('job')" prop="job" width="150"> </el-table-column>
                <el-table-column sortable :label="$t('postalBank')" prop="bp" width="160"> </el-table-column>

                <el-table-column align="right" width="275" fixed="right">

                    <template #header>
                        <el-input v-model="search" size="small" :placeholder="$t('search')" />
                    </template>

                    <template #default="scope">
                        <el-button size="small" type="primary" @click="openStudents(scope.$index, scope.row)">
                            {{ (scope.row.childrenNumber || '0') + ' ' + ( scope.row.childrenNumber > 1 ? $t('students') : $t('student')) }}
                        </el-button>
                        <el-button size="small" @click="editItem(scope.$index, scope.row)">
                            {{ $t('edit') }}
                        </el-button>
                        <el-button size="small" type="danger" @click="deleteItem(scope.$index, scope.row)">
                            {{ $t('delete') }}
                        </el-button>
                    </template>

                </el-table-column>

            </el-table>

            <div style="text-align: center; margin-top: 20px">

                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="setPage"
                    :hide-on-single-page="true"
                    :page-size="pageSize"
                    :current-page="page"
                    :total="total">
                </el-pagination>

            </div>

        </el-card>

        <el-dialog :title="$t('addParent')" v-model="addDialog" width="65%">

            <el-form
                :inline="true"
                :rules="rules"
                :model="addParent"
                label-width="140px"
                label-position="left"
                :ref="addParentForm"
                hide-required-asterisk>

                <el-form-item prop="title" :label="$t('civility')">
                    <el-select
                        v-model="addParent.title"
                        :placeholder="$t('select')"
                        :no-data-text="$t('noData')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 250px">
                        <el-option
                            v-for="item in titles"
                            :key="item.id"
                            :value="item.id"
                            :label="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="name" :label="$t('name')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('name')"
                        v-model="addParent.name"/>
                </el-form-item>

                <el-form-item prop="phone" :label="$t('phone')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('phone')"
                        v-model="addParent.phone"/>
                </el-form-item>

                <el-form-item prop="email" :label="$t('email')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('email')"
                        v-model="addParent.email"/>
                </el-form-item>

                <el-form-item prop="nationality" :label="$t('nationality')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('nationality')"
                        v-model="addParent.nationality"/>
                </el-form-item>

                <el-form-item prop="job" :label="$t('job')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('job')"
                        v-model="addParent.job"/>
                </el-form-item>

                <el-form-item prop="bp" :label="$t('postalBank')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('postalBank')"
                        v-model="addParent.bp"/>
                </el-form-item>

            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelForm(addParentForm)">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="submitForm(addParentForm)">{{ $t('confirm') }}</el-button>
                </span>
            </template>

        </el-dialog>

        <el-dialog :title="$t('editParent')" v-model="editDialog" width="65%">

            <el-form
                :inline="true"
                :rules="rules"
                :model="editParent"
                label-width="140px"
                label-position="left"
                :ref="editParentForm"
                hide-required-asterisk>

                <el-form-item prop="title" :label="$t('civility')">
                    <el-select
                        v-model="editParent.title"
                        :placeholder="$t('select')"
                        :no-data-text="$t('noData')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 250px">
                        <el-option
                            v-for="item in titles"
                            :key="item.id"
                            :value="item.id"
                            :label="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="name" :label="$t('name')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('name')"
                        v-model="editParent.name"/>
                </el-form-item>

                <el-form-item prop="phone" :label="$t('phone')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('phone')"
                        v-model="editParent.phone"/>
                </el-form-item>

                <el-form-item prop="email" :label="$t('email')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('email')"
                        v-model="editParent.email"/>
                </el-form-item>

                <el-form-item prop="nationality" :label="$t('nationality')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('nationality')"
                        v-model="editParent.nationality"/>
                </el-form-item>

                <el-form-item prop="job" :label="$t('job')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('job')"
                        v-model="editParent.job"/>
                </el-form-item>

                <el-form-item prop="bp" :label="$t('postalBank')">
                    <el-input
                        style="width: 250px"
                        :placeholder="$t('postalBank')"
                        v-model="editParent.bp"/>
                </el-form-item>

            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelForm(editParentForm)">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="submitForm(editParentForm)">{{ $t('confirm') }}</el-button>
                </span>
            </template>

        </el-dialog>

     </template>

     <el-alert :title="$t('noCurrentSchoolyearWarning')" type="warning" v-else/>

</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import fr from 'element-plus/lib/locale/lang/fr'

import { Titles } from '@/utils/enums'

import { Roles, Routes } from '@/utils/enums'
import { genPassword } from '@/utils/functions'

import { v4 as uuidv4 } from 'uuid'
import sanitizer from 'string-sanitizer'

import accessManager from '@/mixins/access-manager'

export default {
    name: 'Parents',
    mixins: [accessManager],
    data() {
      return {
        titles: [],
        primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
        editParent: {
            title: '',
            name: '',
            phone: '',
            email: '',
            nationality: '',
            job: '',
            bp: ''
        },
        addParent: {
            title: '',
            name: '',
            phone: '',
            email: '',
            nationality: '',
            job: '',
            bp: ''
        },
        rules: {
            title: [
                {
                    required: true,
                    message: this.$t('fieldRequired'),
                    trigger: 'blur',
                }
            ],
            name: [
                {
                    required: true,
                    message: this.$t('fieldRequired'),
                    trigger: 'blur',
                }
            ],
            phone: [
                {
                    required: false
                }
            ],
            email: [
                {
                    required: false
                }
            ],
            nationality: [
                {
                    required: false
                }
            ],
            job: [
                {
                    required: false
                }
            ],
            bp: [
                {
                    required: false
                }
            ]
        },
        editParentForm: 'editParentForm',
        addParentForm: 'addParentForm',
        currentEditItem: null,
        editDialog: false,
        isLoading: false,
        addDialog: false,
        pageSize: 6,
        locale: fr,
        search: '',
        total: 0,
        page: 1
      }
    },
    created() {

      this.fetchParents()

      this.page = this.cachePage

      var self = this

      this.$_.forIn(Titles, function(value, key) {

          self.titles.push(value)

      })

    },
    computed:{
      ...mapState({
        userData: state => state.session.userData,
        cachePage: state => state.parents.cachePage,
        cacheParents: state => state.parents.cacheParents
      }),
      ...mapGetters({
        getSettingValue: 'settings/getSettingValue'
      }),
      pagedTableData() {

        this.total = this.searching.length

        return this.searching.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)

      },
      searching() {

        if (!this.search) {
            this.total = this.cacheParents.length
            return this.cacheParents
        }

        this.page = 1

        return this.cacheParents.filter(data => data.name.toLowerCase().includes(this.search.toLowerCase()))

      }
    },
    methods: {
      ...mapMutations({
          updateCachePage: 'parents/updateCachePage'
      }),
      ...mapActions({
         fetchStudentsDB: 'students/fetchStudentsDB',
         fetchParentsDB: 'parents/fetchParentsDB',
         fetchUserDB: 'user/fetchUserDB'
      }),
      setPage (val) {

         this.page = val

         this.updateCachePage(val)

      },
      indexMethod(index) {

         return index + ((this.page - 1) * this.pageSize) + 1

      },
      getTitleLabel(id){

          let title = this.titles.find(t => t.id == id)

          return title ? title.label : ''

      },
      openStudents(index, item){

          this.$router.push({
              name: 'ParentStudents' + Routes.BASIC_INFO.NAME,
              params: {
                  parent: item.id
              }
          })

      },
      async genUid(uidSrc, addSalt){

            var uid = sanitizer.sanitize(uidSrc.toLowerCase()).substring(0, 10)

            if (addSalt) uid += this.$_.random(1, 99, false)

            var task = await this.fetchUserDB({
                action: 'checkUserExists',
                params: {
                    uid: uid
                }
            })

            if (task.success && !task.data) {

                return uid

            }else{

                return await this.genUid(uidSrc, true)

            }

      },
      async fetchParents(showLoader = false){

        if (!this.adminSchoolyear) return

        this.isLoading = (this.$_.isArray(this.cacheParents) && this.cacheParents.length === 0) || showLoader

        let resp = await this.fetchParentsDB({
            action: 'getParents',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (!resp.success) this.$message.error(this.$t('anErrorOccured'))

        this.isLoading = false

      },
      addItem(){

           if (this.lockedData){
                this.$message.warning(this.$t('lockedData'))

                return
           }

           this.addDialog = true

      },
      async editItem(index, item){

            if (this.lockedData){
                this.$message.warning(this.$t('lockedData'))

                return
            }

            this.currentEditItem = item

            this.editParent.bp = item.bp
            this.editParent.job = item.job
            this.editParent.name = item.name
            this.editParent.phone = item.phone
            this.editParent.email = item.email
            this.editParent.title = parseInt(item.title)
            this.editParent.nationality = item.nationality

            this.editDialog = true

      },
      deleteItem(index, item){

            if (this.lockedData){
                this.$message.warning(this.$t('lockedData'))

                return
            }

            this.$confirm(this.$t('deleteParentQuestion'), this.$t('delete'), {
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning',
            })
            .then(async () => {

                let task = await this.fetchParentsDB({
                    action: 'deleteParent',
                    params: {
                        id: item.id,
                        user: item.user
                    }
                })

                if (task.success){

                    this.$message.success(this.$t('success'))
                    this.fetchParents(true)

                }else{

                    this.$message.error(this.$t('anErrorOccured'))

                }

            })
            .catch(() => {

            })

      },
      submitForm(ref){

            this.$refs[ref].validate(async (valid) => {
                if (valid) {

                    if (ref === this.addParentForm){

                        let uid = await this.genUid(this.addParent.name, false)

                        let task = await this.fetchParentsDB({
                            action: 'addParent',
                            params: {
                                uid: uid,
                                hash_id: uuidv4(),
                                role: Roles.PARENT,
                                password: genPassword(),

                                bp: this.addParent.bp,
                                job: this.addParent.job,
                                name: this.addParent.name,
                                title: this.addParent.title,
                                phone: this.addParent.phone,
                                email: this.addParent.email,
                                nationality: this.addParent.nationality,
                                schoolyear: this.adminSchoolyear
                            }
                        })

                        if (task.success) this.$message.success(this.$t('success'))
                        else this.$message.error(this.$t('anErrorOccured'))

                    }else{

                        let newData = {}

                        if (this.currentEditItem.title !== this.editParent.title) {
                            newData['title'] = this.editParent.title
                        }

                        if (this.currentEditItem.name !== this.editParent.name) {
                            newData['name'] = this.editParent.name
                        }

                        if (this.currentEditItem.phone !== this.editParent.phone) {
                            newData['phone'] = this.editParent.phone
                        }

                        if (this.currentEditItem.email !== this.editParent.email) {
                            newData['email'] = this.editParent.email
                        }

                        if (this.currentEditItem.job !== this.editParent.job) {
                            newData['job'] = this.editParent.job
                        }

                        if (this.currentEditItem.bp !== this.editParent.bp) {
                            newData['bp'] = this.editParent.bp
                        }

                        if (this.currentEditItem.nationality !== this.editParent.nationality) {
                            newData['nationality'] = this.editParent.nationality
                        }

                        if (!this.$_.isEmpty(newData)){
                            let task = await this.fetchParentsDB({
                                action: 'editParent',
                                params: {
                                    id: this.currentEditItem.id,
                                    data: newData
                                }
                            })

                            if (task.success) this.$message.success(this.$t('success'))
                            else this.$message.error(this.$t('anErrorOccured'))
                        }

                    }

                    this.cancelForm(ref)
                    this.fetchParents(true)

                } else {
                    return false
                }
            })

      },
      cancelForm(ref){

            this.$refs[ref].resetFields()

            if (ref === this.addParentForm) this.addDialog = false
            else this.editDialog = false

      }
    },
}
</script>